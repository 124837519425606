// kthStaffProfileConfigs.js
import { getImageUrl } from "../../utils/helpers";

export const kthStaffProfileConfigs = {
  "roque-thuo": {
    name: "Roque Thuo",
    role: ["Attorney", "Engineer"],

    avatar: getImageUrl("kama-thuo-pic.jpg"),
    summary: [
      "Attorney Licensed in Arizona and California",
      "Registered to Practice Before the U.S. Patent and Trademark Office",
      "Professional Engineer (Electrical) Licensed in Arizona",
    ],
    introParagraphs: [
      "Patent Attorney | AI & Technology Law | Licensed Engineer",
      "Roque Thuo advises clients on engineering and technology matters, including patent drafting, analysis, portfolio management, and AI technology counseling. With 8 years as a patent attorney at an Am Law 50 firm, and 14 years as an engineer at Fortune 500 high-tech companies, Roque combines legal and hands-on technical expertise to help clients navigate complex intellectual property challenges.",
      "Roque’s patent practice focuses on drafting, prosecuting, and managing patent portfolios in wireless communications, analog and RF circuits, antenna and radio front-end modules, machine learning, high-tech electrical, and computer systems. He provides patentability assessments and portfolio optimization strategies, helping startups and established corporations protect and monetize their innovations. Roque also advises clients on technology transactions, patent licensing, and developing patent strategies for AI systems, tools, and models.",
      "In AI counseling, Roque guides clients through AI integration, deployment, and evaluation while ensuring compliance with evolving regulations. His technical background enables him to assess the legal implications of AI model training and use, ensuring businesses can seamlessly integrate these technologies into their operations.",
      "Roque also offers legal support for wireless telecommunications projects, including 5G, IoT Low-Power Wide-Area Networks (LPWAN), distributed antenna systems (DAS), small cells, neutral host networks, fixed wireless access, and microwave backhaul.",
      "Before transitioning to law, Roque designed and verified analog and mixed-signal circuits for communication transceivers. His experience includes designing and modeling analog and RF blocks, as well as designing and deploying wireless links for broadband, fixed wireless access and wireless backhaul. Roque is a registered professional electrical engineer and licensed low-voltage communication contractor in Arizona.",
    ],
    professionalRecognition: [
      "Selected for inclusion in Best Lawyers: Ones to Watch, 2021 – 2022",
    ],
    relatedEmployment: [
      "Perkins Coie, LLP, Patent Associate/Counsel, 2014 – 2022",
      "Intel Corporation, Hard-IP Analog Team, Analog Mixed-Signal Engineer, 2005 – 2013",
      "Rfwel Engineering, LLC, Wireless Engineer (Part Time), 2005 – 2014",
      "Microchip Technology Inc, Design Engineer, 2000 – 2005",
      "Microchip Technology Inc., Process Engineer, 1999 – 2000",
    ],
    experience: [
      {
        title: "AI TECHNOLOGY",
        description:
          "Analyzed AI patents to assess risk exposure and identify licensing opportunities. Drafted patents related to use of machine learning in various applications including in wireless communication such as for 5G NR network slicing and resource allocation. Evaluated AI applications for legal risk associated with training data, and terms of use.",
      },
      {
        title: "5G TECHNOLOGY",
        description:
          "Drafted 5G technology patents in areas including radio transceiver power amplifiers, multiple aspects of 5G NR baseband processors for user and base station equipment, and multiple aspects of 5G Core Network functional nodes.",
      },
      {
        title: "WIRELESS EQUIPMENT",
        description:
          "Drafted patents in consumer and industrial wireless devices, including patents related to circuit, system, and radio front-end components such as integrated antennas for implanted medical devices and aerial vehicles.",
      },
      {
        title: "WIRELESS STANDARDS",
        description:
          "Analyzed quality of hundreds of patents related to various wireless standards, including 3GPP 4G LTE, 5G NR, and IEEE WLAN/WPAN standards.",
      },
      {
        title: "TARGETED PROSECUTION",
        description:
          "Advised clients on drafting and prosecuting continuation claims targeting various competitor products and wireless standards.",
      },
      {
        title: "WIRELESS PATENT PORTFOLIOS",
        description:
          "Evaluated large patent portfolios of hundreds of wireless patents for acquisition between large Fortune 500 companies. Experienced in developing monetization strategies targeting different vertical markets and diverse wireless patent portfolios.",
      },
    ],
    areasOfFocus: {
      practices: [
        "Patent Analytics, Strategy & Solutions",
        "Patent Prosecution & Portfolio Counseling",
        "Intellectual Property Law",
        "AI Technology Counseling",
        "Wireless Telecommunications Law",
      ],
      industries: [
        "5G Technology",
        "Artificial Intelligence & Machine Learning",
      ],
    },
    barAdmissions: [
      "Arizona",
      "California",
      "U.S. Patent and Trademark Office",
      "U.S. Court of Appeals for the Ninth Circuit",
      "U.S. District Court for the District of Arizona",
      "U.S. District Court for the Northern District of California",
    ],
    education: [
      "Arizona State University: J.D., summa cum laude, Deans Award in Recognition of Outstanding Performance, 2014.",
      "Arizona State University: Law, Science, and Technology Certificate with a specialization in Intellectual Property, 2014.",
      "Arizona State University: M.S., Electrical Engineering, 2001.",
      "Arizona State University: B.S., Electrical Engineering and Technology, summa cum laude, 1999.",
    ],
    skills: {
      lics: [
        "Registered Professional Engineering (Electrical) - AZ BTR #52845",
        "Low Voltage Communications Contractor (Commercial) - AZ ROC #322820",
      ],
      appDevelopment: [
        "Front-End: React/JavaScript",
        "Back-End: Django/Python, PHP",
      ],
      otherProgramming: ["PowerShell Scripting for Workflow Automation"],
      aiTools: ["AI Automation Development Tools"],
    },
    languages: ["Swahili"],
    news: [
      {
        title:
          "Best Lawyers® 2022 Recognized Roque Thuo for Inclusion in Best Lawyers: Ones to Watch",
        description:
          "Since its inception in 1983, Best Lawyers has become universally regarded as an important guide to legal excellence. The 28th edition of The Best Lawyers in America® (2022) includes attorneys in practice areas covering all 50 states and the District of Columbia, and inclusion in this year’s publication is based on more than 5.5 million detailed evaluations of attorneys by other attorneys. Best Lawyers: Ones to Watch recognizes attorneys who have been in practice 5–9 years for outstanding professional excellence in the United States. Because Best Lawyers is based on an exhaustive peer-review survey and attorneys are not required or allowed to pay a fee to be listed, inclusion in Best Lawyers is considered a singular honor.",
      },
      {
        title:
          "Roque Thuo Recognized by the Leadership Council on Legal Diversity",
        description:
          "Roque Thuo was recognized by the Leadership Council on Legal Diversity (LCLD) as part of the council’s Fellows and Pathfinder programs.",
      },
    ],
  },
  "ray-mutai": {
    name: "Ray Mutai",
    role: ["Engineer"],

    summary: ["Wireless Engineer", "Patent Analyst", "AI Automation"],
    introParagraphs: [
      "Ray is an experienced Wireless Engineer with a strong background in business research, wireless technology, AI, data analysis, and telecom engineering. Skilled in designing and optimizing wireless communication systems, leveraging AI for data-driven insights, and conducting market research to inform strategic decisions.",
      "Ray has a proven ability to implement and manage complex telecom infrastructures, delivering scalable and innovative solutions. Excellent communicator with a collaborative approach, adept at translating technical concepts into actionable business strategies.",
    ],
    education: [
      "The Technical University of Kenya, B. Technology, Electrical & Electronics Technology, 2015.",
      "Technical Professional Certification Curriculum, Sierra Wireless, 2020.",
    ],
    skills: {
      appDevelopment: [
        "Front-End: React/JavaScript",
        "Back-End: Django/Python",
      ],
      otherProgramming: ["PowerShell Scripting for Workflow Automation"],
      aiTools: ["AI Automation Development Tools"],
    },
  },
  "bill-kimutai": {
    name: "Bill Kimutai",
    role: ["Data Scientist"],
    summary: [
      "Data Analyst",
      "Patent Analyst",
      "AI Automation, Full Stack & ML Developer",
    ],
    introParagraphs: [
      "Bill is a data analyst with a multidisciplinary background in Communications, Public Relations, Finance and Wireless Engineering. Proven ability to leverage Python otherProgramming, React/JavaScript front-end development, and AI technologies to deliver innovative business solutions.",
      "Skilled at bridging the gap between technical complexities and business needs, providing data-driven insights that empower informed decision-making. Passionate about exploring the intersection of AI and intellectual property, with a keen interest in translating technical advancements into strategic business opportunities.",
    ],
    skills: {
      appDevelopment: [
        "Front-End: React/JavaScript",
        "Back-End: Django/Python, PHP",
      ],
      otherProgramming: ["PowerShell Scripting for Workflow Automation"],
      aiTools: ["AI Automation Development Tools"],
    },
  },
  "brian-kibet": {
    name: "Brian Kibet",
    role: ["Engineer"],
    summary: [
      "Wireless Telecom Engineer",
      "AI Automation, Full Stack & ML Developer",
    ],
    skills: {
      appDevelopment: [
        "Front-End: React/JavaScript",
        "Back-End: Django/Python, PHP",
      ],
      otherProgramming: ["PowerShell Scripting for Workflow Automation"],
      aiTools: ["AI Automation Development Tools"],
    },
  },
  "lameck-wekesa": {
    name: "Lameck Wekesa",
    role: ["Engineer"],
    summary: ["Controls Engineer", "AI Automation"],
  },
  "keith-clinton": {
    name: "Keith Clinton",
    role: ["Engineer"],
    summary: ["AI Automation, Full Stack & ML Developer"],
    skills: {
      appDevelopment: [
        "Front-End: React/JavaScript",
        "Back-End: Django/Python, PHP",
      ],
      aiTools: ["AI Automation Development Tools"],
    },
  },
  "sydney-keter": {
    name: "Sydney Keter",
    role: ["Engineer"],
    summary: ["AI Automation"],
  },
  "colletar-nthambi": {
    name: "Colletar Nthambi",
    role: ["Engineer"],
    summary: ["Intern - AI Automation"],
    skills: {
      appDevelopment: ["Back-End: Django/Python"],
      otherProgramming: [
        "PowerShell Scripting for Workflow Automation",
        "Ladder Logic Language for PLCs",
      ],
    },
  },
  "sammy-gichiri": {
    name: "Sammy Gichiri",
    role: ["Engineer"],
    summary: ["Intern - AI Automation"],
    skills: {
      certs: ["Huawei Certified ICT Associate, Routing and Switching"],
      appDevelopment: ["Java Android Development"],
      otherProgramming: ["PowerShell Scripting"],
    },
  },
  "florence-wanjiko": {
    name: "Florence Wanjiko",
    role: ["Business Professional"],
    summary: ["Business Analyst"],
  },
  "purity-kwamboka": {
    name: "Purity Kwamboka",
    role: ["Business Professional"],
    summary: ["Business Analyst", "Client Admin"],
  },
  "jedidah-maina": {
    name: "Jedidah Maina",
    role: ["Business Professional"],
    summary: ["Business Analyst"],
  },
  "mercy-ngendo": {
    name: "Mercy Ngendo",
    role: ["Business Professional"],
    summary: ["Business Analyst"],
  },
};
