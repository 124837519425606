import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import LoginForm from "../LoginForm";
import Logout from "../Logout";
import KamathuoHome from "./KamathuoHome";
import AboutKamathuo from "./AboutKamathuo";
import KamathuoServicesPatents from "./KamathuoServicesPatents";
import KamathuoServicesAi from "./KamathuoServicesAi";
import KamathuoServicesWireless from "./KamathuoServicesWireless";
import KamathuoPrivacyPolicy from "./KamathuoPrivacyPolicy";
import KamathuoCookiePolicy from "./KamathuoCookiePolicy";
import KamathuoDisclaimer from "./KamathuoDisclaimer";
import KamathuoContactUs from "./KamathuoContactUs";
import KamathuoProfessionals from "./KamathuoProfessionals";
import KamathuoProfile from "./KamathuoProfile";
import KamathuoNewsInsights from "./KamathuoNewsInsights";
import KamathuoAiResources from "./KamathuoAiResources";
import KamathuoAiComplianceChecker from "./KamathuoAiComplianceChecker";
import KamathuoCaseMgmt from "./KamathuoCaseMgmt";

const KamathuoRoutes = ({ user = "", baseConfig }) => {
  let routes = useRoutes([
    //--------- Public paths --------------------------------------------------------
    {
      path: "/",
      element: <KamathuoHome baseConfig={baseConfig} />,
    },
    {
      path: "/us/*",
      element: <Navigate to="/" replace />,
    },
    { path: "/aboutus", element: <AboutKamathuo baseConfig={baseConfig} /> },
    {
      path: "/login",
      element: <LoginForm termsLink="/disclaimer" linkTarget="_blank" />,
    },
    //@TODO -- for now account form is just contactus form.
    {
      path: "/account",
      element: <KamathuoContactUs baseConfig={baseConfig} />,
    },
    {
      path: "/logout",
      element: <Logout baseConfig={baseConfig} />,
    },
    {
      path: "/contact",
      element: <KamathuoContactUs baseConfig={baseConfig} />,
    },
    {
      path: "/disclaimer",
      element: <KamathuoDisclaimer baseConfig={baseConfig} />,
    },
    {
      path: "/professionals",
      element: <KamathuoProfessionals baseConfig={baseConfig} />,
    },
    {
      path: "/professionals/:id",
      element: <KamathuoProfile baseConfig={baseConfig} />,
    },
    {
      path: "/privacy-policy",
      element: <KamathuoPrivacyPolicy baseConfig={baseConfig} />,
    },
    {
      path: "/cookie-policy",
      element: <KamathuoCookiePolicy baseConfig={baseConfig} />,
    },
    //--------- Service Public Paths --------------------------------------------------------
    {
      path: "/patents",
      element: <KamathuoServicesPatents baseConfig={baseConfig} />,
    },
    {
      path: "/ai",
      element: <KamathuoServicesAi baseConfig={baseConfig} />,
    },
    {
      path: "/wireless",
      element: <KamathuoServicesWireless baseConfig={baseConfig} />,
    },
    {
      path: "/telecom",
      element: <Navigate to="/wireless" replace />,
    },
    //--------- Content Paths --------------------------------------------------------
    {
      path: "/news-insights",
      element: <KamathuoNewsInsights baseConfig={baseConfig} />,
    },
    {
      path: "/patent-ai-resources",
      element: <KamathuoAiResources baseConfig={baseConfig} />,
    },
    {
      path: "/ai-compliance-checker",
      element: <KamathuoAiComplianceChecker baseConfig={baseConfig} />,
    },
    //--------- Authenticated Paths --------------------------------------------------------
    {
      path: "/case-management",
      element: user ? (
        <KamathuoCaseMgmt baseConfig={baseConfig} />
      ) : (
        <Navigate to="/login" />
      ),
    },
  ]); // end useRoutes
  return routes;
};

export default KamathuoRoutes;
