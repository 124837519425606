import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  Box,
  Divider,
  IconButton,
  CssBaseline,
  Container,
  Menu,
  MenuItem,
  Button,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { styled, useTheme } from "@mui/material/styles";
import NavbarSaas from "./NavbarSaas";
import parse from "html-react-parser";
import _ from "lodash";
import { useAuth } from "../context/AuthContext";
import { getImageUrl } from "../utils/helpers";

//-----------------------------------
// Drawer Dimensions
//-----------------------------------
const drawerWidth = 240;
const collapsedDrawerWidth = 56;

//-----------------------------------
// App Bar Styles
//-----------------------------------
const AppBarShift = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "appBarColor",
})(({ theme, appBarColor }) => ({
  backgroundColor: appBarColor || "#94618E", // Default color if not specified
  zIndex: theme.zIndex.drawer + 1,
}));

//-----------------------------------
// Drawer Styles
//-----------------------------------
const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
}));

//-----------------------------------
// Main Menu Styles
//-----------------------------------
const Main = styled("main")(({ theme }) => ({
  flexGrow: 1, // This allows the main content to grow
  padding: theme.spacing(3),
  backgroundColor: "#F5F5F5",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

//-----------------------------------
// Footer Styles
//-----------------------------------
const Footer = styled("footer")(({ theme }) => ({
  marginTop: "auto", // This automatically takes any available space
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Base Component Saas
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

const BaseComponentSaas = ({ baseConfig }) => {
  const { user } = useAuth(); // Access user data directly from AuthContext
  const location = useLocation();
  const {
    navbarConfig,
    RoutesComponent,
    welcomeText,
    footerText,
    footerLegalLinks,
    siteBranding,
  } = baseConfig;
  const ViewWorksheetButton = baseConfig.WorksheetComponent;
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const [drawerOpen, setDrawerOpen] = useState(true);
  // Have drawer closed by default for small screens (mobile)
  const [drawerOpen, setDrawerOpen] = useState(!isSmallScreen);
  const [openSubmenus, setOpenSubmenus] = useState({});

  //----------------------------------------------------------------------------
  // Drawer Functions
  //----------------------------------------------------------------------------
  // Function to toggle drawer open state and collapse all submenus if the drawer is closing
  const toggleDrawer = () => {
    if (drawerOpen) {
      // If the drawer is open, close it and collapse all submenus
      setOpenSubmenus({}); // Collapse all submenus
    }
    setDrawerOpen(!drawerOpen);
  };

  const closeDrawer = () => {
    if (drawerOpen) {
      setDrawerOpen(false);
      setOpenSubmenus({}); // Collapse all submenus
    }
  };

  // // Function to open the drawer if it is closed when a menu icon is clicked
  const handleMenuClick = (title, hasChildren = false) => {
    if (!drawerOpen) {
      // Open the drawer if it's closed
      setDrawerOpen(true);
      // If the clicked menu item has children, set it to be open
      if (hasChildren) {
        setOpenSubmenus({ [title]: true });
      }
    } else {
      // Toggle the submenu open state
      setOpenSubmenus((prevState) => ({
        ...prevState,
        [title]: !prevState[title],
      }));
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Attach an event listener to the main content for small screens
  // Close drawer if open when you click on main content
  useEffect(() => {
    const mainContent = document.getElementById("main-content");

    if (isSmallScreen && drawerOpen) {
      mainContent.addEventListener("click", closeDrawer);
    }

    // Clean up the event listener when the component unmounts or dependencies change
    return () => {
      mainContent.removeEventListener("click", closeDrawer);
    };
  }, [isSmallScreen, drawerOpen]);

  //----------------------------------------------------------------------------
  // Page-Specific Footer
  //----------------------------------------------------------------------------
  // Get page-specific additional footer text from NavbarConfig.js
  const getPageFooterText = (path) => {
    const findFooterText = (config) => {
      for (let item of config) {
        // Check if the current path starts with the configured path (accounts for dynamic paths like /devices/device-detail/DEVID or paths with query strings)
        if (path.startsWith(item.path)) {
          if (item.pageFooterText) {
            return item.pageFooterText;
          }
        }
        if (item.children) {
          const text = findFooterText(item.children);
          if (text) return text;
        }
      }
      return "";
    };
    return findFooterText(navbarConfig);
  };

  const pageFooterText = getPageFooterText(location.pathname);

  //----------------------------------------------------------------------------
  // Remvoe Text Decoration in Menu Links
  //----------------------------------------------------------------------------
  const removeUnderlineInLink = (node) => {
    // Check if the node is an <a> tag and set textDecoration to none
    if (node.type === "tag" && node.name === "a") {
      return (
        <a
          href={node.attribs.href}
          target={node.attribs.target}
          style={{ textDecoration: "none" }}
        >
          {node.children.map((child) => {
            // Assuming children are simple text or other tags that do not need further parsing
            if (child.type === "text") {
              return child.data;
            }
            // Here you might handle other types of children if necessary
          })}
        </a>
      );
    }
  };

  //-------------------------------------------------------------------------------
  // Render Account Menu
  //-------------------------------------------------------------------------------
  const _renderAccountMenu = () => {
    return (
      <>
        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          sx={{ mr: 2 }}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {user
            ? [
                // Use array instead of fragment for multiple menu items
                <MenuItem
                  key="my-account"
                  onClick={handleClose}
                  component={RouterLink}
                  to="/account"
                >
                  <Button variant="text" startIcon={<ManageAccountsIcon />}>
                    My Account
                  </Button>
                </MenuItem>,
                <MenuItem
                  key="contact"
                  onClick={handleClose}
                  component={RouterLink}
                  to="/contact"
                >
                  <Button variant="text" startIcon={<ContactMailIcon />}>
                    Contact Us
                  </Button>
                </MenuItem>,
                <MenuItem
                  key="logout"
                  onClick={handleClose}
                  component={RouterLink}
                  to="/logout"
                >
                  <Button variant="text" startIcon={<LogoutIcon />}>
                    Sign out
                  </Button>
                </MenuItem>,
              ]
            : [
                // Use array instead of fragment for multiple menu items
                <MenuItem
                  key="login"
                  onClick={handleClose}
                  component={RouterLink}
                  to="/login"
                >
                  <Button variant="text" startIcon={<LoginIcon />}>
                    Sign in
                  </Button>
                </MenuItem>,
                <MenuItem
                  key="my-account"
                  onClick={handleClose}
                  component={RouterLink}
                  to="/account"
                >
                  <Button variant="text" startIcon={<AppRegistrationIcon />}>
                    Get Access
                  </Button>
                </MenuItem>,
                <MenuItem
                  key="contact"
                  onClick={handleClose}
                  component={RouterLink}
                  to="/contact"
                >
                  <Button variant="text" startIcon={<ContactMailIcon />}>
                    Contact Us
                  </Button>
                </MenuItem>,
              ]}
        </Menu>
      </>
    );
  };

  //-------------------------------------------------------------------------------
  // Render Full Page
  //-------------------------------------------------------------------------------

  return (
    <Box sx={{ display: "flex", minHeight: "100vh", flexDirection: "column" }}>
      <CssBaseline />
      <AppBarShift position="fixed" appBarColor={siteBranding?.appBarColor}>
        <Toolbar>
          {/* Logo and Toolbar content */}
          {siteBranding?.logo && (
            <RouterLink to="/" style={{ textDecoration: "none" }}>
              <img
                src={getImageUrl(siteBranding.logo)}
                alt="Logo"
                style={{
                  height: 64,
                  marginRight: "40px",
                  marginLeft: "32px",
                }}
              />
            </RouterLink>
          )}

          <Typography variant={isSmallScreen ? "h6" : "h5"} noWrap>
            {welcomeText}
          </Typography>

          {user && !isSmallScreen && (
            <>
              <Typography variant="h5" noWrap sx={{ ml: 2 }}>
                |
              </Typography>
              <Typography variant="h6" noWrap sx={{ ml: 2 }}>
                {`Welcome ${user.first_name}`}
              </Typography>
            </>
          )}

          {/* Spacer to push the account icon to the right */}
          <Box sx={{ flexGrow: 1 }} />

          {/* Conditionally Render Worksheet Button if it exists for app */}
          {ViewWorksheetButton && <ViewWorksheetButton />}

          {/* Account icon and dropdown menu */}
          {_renderAccountMenu()}
        </Toolbar>
      </AppBarShift>

      <Drawer
        variant="permanent"
        open={drawerOpen}
        sx={{
          width: drawerOpen ? drawerWidth : collapsedDrawerWidth,
          "& .MuiDrawer-paper": {
            width: drawerOpen ? drawerWidth : collapsedDrawerWidth,
            backgroundColor: siteBranding?.drawerColor || "#94618E", // Default color if not specified
            overflowX: "hidden",

            marginTop: 3,
          },
        }}
      >
        {/* Spacer above Drawer content */}
        <DrawerHeader />

        <IconButton
          onClick={toggleDrawer}
          sx={{ justifyContent: "flex-end", color: "white" }}
        >
          {drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>

        <Divider />
        {/* Navbar */}
        <List>
          <NavbarSaas
            menuItems={navbarConfig}
            user={user}
            handleMenuClick={handleMenuClick}
            openSubmenus={openSubmenus}
          />
        </List>
      </Drawer>

      {/* Main content */}
      <Main
        // Need id for event listener
        id="main-content"
        sx={{
          marginLeft: `${drawerOpen ? drawerWidth : collapsedDrawerWidth}px`,
          ...(isSmallScreen && {
            marginLeft: `${collapsedDrawerWidth}px`,
          }),
        }}
      >
        {/* Empty Toolbar to offset the main content below the AppBar */}
        <Toolbar />

        <Container maxWidth="x1">
          {/* Main Body Content */}
          {/* maxWidth="x1" allows content to fill full widht. In nested components, if need some content to be centered on less than full with use maxWidth="lg" */}
          {RoutesComponent && (
            <RoutesComponent user={user} baseConfig={baseConfig} />
          )}
        </Container>
      </Main>

      {/* Footer content */}
      <Footer
        sx={{
          marginLeft: `${drawerOpen ? drawerWidth : collapsedDrawerWidth}px`,
          ...(isSmallScreen && {
            marginLeft: `${collapsedDrawerWidth}px`,
          }),
        }}
      >
        <Container maxWidth="x1">
          {/* Footer Body Content */}
          {pageFooterText && (
            <Typography variant="body2" color="textSecondary" align="center">
              {parse(pageFooterText, { replace: removeUnderlineInLink })}
            </Typography>
          )}
          <Typography variant="body2" color="textSecondary" align="center">
            ©{new Date().getFullYear()}.{" "}
            {parse(footerText, { replace: removeUnderlineInLink })}
          </Typography>

          <Typography variant="body2" color="textSecondary" align="center">
            {parse(footerLegalLinks, { replace: removeUnderlineInLink })}
          </Typography>
        </Container>
      </Footer>
    </Box>
  );
};

export default BaseComponentSaas;
