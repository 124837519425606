import React, { useState } from "react";
import { Link as RouterLink, NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Grid,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  useTheme,
  useMediaQuery,
  ListItemIcon,
  Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { useAuth } from "../context/AuthContext";

const NavbarWeb = ({ navConfig, baseConfig }) => {
  const { user } = useAuth();
  const { headerTextColor } = baseConfig.siteBranding;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenuTitle, setOpenMenuTitle] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openMobileMenuTitle, setOpenMobileMenuTitle] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleMenuOpen = (event, title) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuTitle(title);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenuTitle(null);
  };

  const handleMouseEnter = (event, title) => {
    if (anchorEl) return; // Prevents opening multiple menus at the same time
    handleMenuOpen(event, title);
  };

  const handleDrawerToggle = (shouldClose = true) => {
    if (shouldClose) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleMobileMenuToggle = (title) => {
    setOpenMobileMenuTitle(openMobileMenuTitle === title ? null : title);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <List>
        {navConfig.map((item) => {
          if (item.authRequired && !user) {
            return null;
          }
          return (
            <div key={item.title}>
              <ListItem
                button
                onClick={() => {
                  if (item.children.length > 0) {
                    handleMobileMenuToggle(item.title);
                  } else {
                    handleDrawerToggle();
                  }
                }}
                component={item.children.length > 0 ? "div" : RouterLink}
                to={item.children.length > 0 ? undefined : item.path}
              >
                <ListItemText primary={item.title} />
                {item.children.length > 0 ? (
                  openMobileMenuTitle === item.title ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItem>
              {item.children.length > 0 && (
                <Collapse
                  in={openMobileMenuTitle === item.title}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.children.map((child) => (
                      <ListItem
                        button
                        component={NavLink}
                        to={child.path}
                        key={child.title}
                        sx={{ pl: 4 }}
                        onClick={() => handleDrawerToggle()}
                      >
                        <ListItemText primary={child.title} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          );
        })}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="static"
      style={{ background: "#EEE", boxShadow: "none" }}
      elevation={0}
    >
      <Toolbar>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={() => handleDrawerToggle()}
              sx={{ color: headerTextColor }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={mobileOpen}
              onClose={() => handleDrawerToggle()}
            >
              {drawer}
            </Drawer>
          </>
        ) : (
          <Grid container alignItems="center" spacing={2}>
            {navConfig.map((item) => {
              if (item.authRequired && !user) {
                return null;
              }
              return (
                <Grid item key={item.title}>
                  <Box onMouseLeave={handleMenuClose}>
                    <Button
                      sx={{ color: headerTextColor }}
                      onMouseEnter={(e) => handleMouseEnter(e, item.title)}
                      component={item.children.length > 0 ? "div" : RouterLink}
                      to={item.children.length > 0 ? undefined : item.path}
                      endIcon={
                        item.children.length > 0 ? (
                          openMenuTitle === item.title ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        ) : null
                      }
                    >
                      {item.title}
                    </Button>
                    {item.children.length > 0 && (
                      <Menu
                        id={`menu-${item.title}`}
                        anchorEl={anchorEl}
                        open={openMenuTitle === item.title}
                        onClose={handleMenuClose}
                        MenuListProps={{
                          onMouseLeave: handleMenuClose,
                        }}
                      >
                        {item.children.map((child) => (
                          <MenuItem
                            key={child.title}
                            component={NavLink}
                            to={child.path}
                          >
                            {child.title}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavbarWeb;
