import React from "react";
import { Alert, Grid } from "@mui/material";

const KamathuoContactUsCommonContent = ({ children }) => {
  const agreementText =
    "I understand and agree that NO ATTORNEY/CLIENT RELATIONSHIP will be created by this submission unless a separate written agreement agreeing to the representation is executed.";
  const organizationErrorText =
    "Required - we currently do not handle matters from individuals";

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={12} align="center">
        <Alert severity="warning">
          *** DO NOT SUBMIT CONFIDENTIAL INFORMATION BELOW ***
        </Alert>
      </Grid>

      <Grid item xs={12} align="left">
        <Alert severity="info">
          No attorney-client relationship is established by submitting the form
          below until an engagement letter agreeing to the representation is
          executed. Enter only your contact details and general nature of your
          legal question or matter.
        </Alert>
      </Grid>

      <Grid item xs={12}>
        {children(agreementText, organizationErrorText)}
      </Grid>
    </Grid>
  );
};

export default KamathuoContactUsCommonContent;
