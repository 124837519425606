import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Container, Button, Divider, Box } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import ReusableTitle from "../common/ReusableTitle";
import { faMicrochipAi } from "@fortawesome/pro-solid-svg-icons";
import SetDocumentMeta from "../common/SetDocumentMeta";
import BodyContentBlock from "../common/BodyContentBlock";
import KamathuoAiLabeling from "./KamathuoAiLabeling";
import KamathuoAiEvaluation from "./KamathuoAiEvaluation";
import KamathuoAiPatents from "./KamathuoAiPatents";
import * as CONFIG from "./utils/kthGlobalLabelsVars";

const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const KamathuoServicesAi = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "AI Technology Lawyer | Evaluation, Compliance & Data Annotation",
    description:
      "Ensure your AI systems are accurate, compliant, and optimized for legal analysis. Our expert legal team provides AI evaluation, training data labeling, and annotation services tailored to your needs. Specializing in AI patent tools.",
    keywords:
      "AI legal evaluation, AI training data labeling, AI annotation, legal AI services, AI compliance, AI in intellectual property law, AI for patent analysis",
  });

  // Check if there is a # in URL and if so navigate to that anchor
  useEffect(() => {
    const hash = window.location.hash.substring(1); // Remove the '#' character
    if (hash) {
      scrollToElement(hash);
    }
  }, []);

  // Define the content for the body
  const bodyContent = [
    {
      contentId: "ai_patents",
      image: "kth-ai-in-patent-analysis.png",
      title: "AI in Patent Analysis",
      text: "Kama Thuo, PLLC counsels clients on the effective use of AI in patent analysis, including prior art searching, identifying patent licensing targets, and \
      understanding portfolio content and gaps. In addition to analyzing patents, Kama Thuo guides clients on selecting or using AI patent drafting or analysis tools, \
      and training or fine-tuning AI models.",
      imagePosition: "right",
      link: "#ai-patent-analysis",
      linkExternal: false,
    },
    {
      contentId: "ai_evaluation",
      image: "kth-ai-guidance.png",
      title: "AI Application Evaluation",
      text: "Kama Thuo, PLLC support clients in testing and evaluating AI outputs for bias and legal compliance. Our services also extend to ensuring AI systems adhere to privacy and \
      data protection laws, offering strategic advice to navigate the complex regulatory landscape.",
      imagePosition: "left",
      link: "#ai-legal-evaluation",
      linkExternal: false,
      newsLink: "/news-insights?category=ai-evaluation",
      newsLinkExternal: false,
    },
    {
      contentId: "ai_labeling_services",
      image: "kth-head-2-ai.png",
      title: "AI Training Data Labeling for Legal Use",
      text: "Kama Thuo, PLLC offers specialized AI annotation, labeling, and tagging services tailored to the legal context, particularly for patent law and technology transactions. \
      Leveraging our status as a law firm, we offer unique advantages in certain contexts, including attorney-client privilege and attorney work product protection, \
      when the work is conducted in anticipation of litigation or for the purpose of providing legal opinions. These protections, together with our ethical duty of confidentiality, \
      ensure that sensitive information remains secure and confidential during and after such assignments.",
      imagePosition: "right",
      link: "#ai-legal-annotation",
      linkExternal: false,
      newsLink: "/news-insights?category=ai-training-fine-tuning",
      newsLinkExternal: false,
    },
    {
      contentId: "ai_transactions",
      image: "kth-ai-transactions.png",
      title: "AI Technology Transactions",
      text: "Kama Thuo, PLLC negotiates and drafts agreements related to the licensing, sale, and acquisition of AI-related patents and technology. \
      We also counsel clients on the use of AI in technology transactions.",
      imagePosition: "left",
      newsLink: "/news-insights?category=tech-transactions",
      newsLinkExternal: false,
    },
    {
      contentId: "ai_litigation",
      image: "kth-ai-litigation.png",
      title: "AI in Litigation",
      text: "Kama Thuo, PLLC supports law firms and companies in conducting pre-suit Rule 11 investigations of AI applications, ensuring that work product is protected from discovery, or \
      properly preserved for production in litigation. Our services also include evaluating AI-generated legal content ensuring its accuracy and relevance, thereby providing \
      robust support in complex technology cases.",
      imagePosition: "right",
      newsLink: "/news-insights?practice_group=ai",
      newsLinkExternal: false,
    },
  ];

  return (
    <Grid container spacing={2} className="link-no-decoration">
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Container>
          <ReusableTitle
            title="Legal Counseling for AI & ML"
            isMainHeading={true}
            siteBranding={baseConfig.siteBranding}
            icon={{ faIcon: faMicrochipAi }}
          />
        </Container>
        <Container>
          <Button
            startIcon={<NewspaperIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 2,
              backgroundColor: "#96858F",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
            component={RouterLink}
            to="/news-insights?practice_group=ai"
          >
            News & Insights: AI
          </Button>
          <Button
            startIcon={<HomeRepairServiceIcon />}
            variant="contained"
            sx={{
              mt: 2,
              ml: 2,
              backgroundColor: "#96858F",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
            component={RouterLink}
            to="/patent-ai-resources"
          >
            Patent AI Resources
          </Button>
        </Container>
      </Grid>
      <Grid item xs={12}>
        {bodyContent.map((content, index) => (
          <BodyContentBlock
            key={index}
            content={content}
            baseConfig={baseConfig}
          />
        ))}
      </Grid>
      <Grid item xs={12} sx={{ mt: 4 }}>
        <Container>{CONFIG.RFW_HORIZDOT}</Container>
      </Grid>

      <Grid item xs={12} id="ai-patent-analysis">
        <KamathuoAiPatents baseConfig={baseConfig} />
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <Container>{CONFIG.RFW_HORIZDOT}</Container>
      </Grid>

      <Grid item xs={12} id="ai-legal-evaluation">
        <KamathuoAiEvaluation baseConfig={baseConfig} />
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <Container>{CONFIG.RFW_HORIZDOT}</Container>
      </Grid>

      <Grid item xs={12} id="ai-legal-annotation">
        <KamathuoAiLabeling baseConfig={baseConfig} />
      </Grid>
    </Grid>
  );
};

export default KamathuoServicesAi;
