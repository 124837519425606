import React from "react";
import { Typography, Paper, Box, Container } from "@mui/material";

const DmcaNotice = () => {
  return (
    <Container maxWidth="md">
      <Paper
        style={{
          padding: "20px",
          marginTop: "20px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Digital Millennium Copyright Act (DMCA) Notice
        </Typography>

        <Typography variant="h6" gutterBottom>
          Copyright Policy
        </Typography>
        <Typography paragraph>
          Patent Analytics Inc. respects the intellectual property rights of
          others and expects its users to do the same. It is our policy to
          respond appropriately to notices of alleged copyright infringement
          that comply with the Digital Millennium Copyright Act (DMCA) and other
          applicable intellectual property laws.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Copyright Infringement Notification under the DMCA
        </Typography>
        <Typography paragraph>
          If you believe that your copyrighted work has been copied in a way
          that constitutes copyright infringement and is accessible through a
          service provided by Patent Analytics Inc., you may notify our
          copyright agent in accordance with the DMCA. To be effective, the
          notification must be a written communication that includes the
          following:
        </Typography>
        <Box pl={2}>
          <Typography paragraph>
            1. A physical or electronic signature of a person authorized to act
            on behalf of the owner of an exclusive right that is allegedly
            infringed.
          </Typography>
          <Typography paragraph>
            2. Identification of the copyrighted work claimed to have been
            infringed.
          </Typography>
          <Typography paragraph>
            3. Identification of the material that is claimed to be infringing
            and that is to be removed.
          </Typography>
          <Typography paragraph>
            4. Information sufficient to permit the service provider to contact
            the complaining party.
          </Typography>
          <Typography paragraph>
            5. A statement that the complaining party has a good faith belief
            that use of the material is not authorized.
          </Typography>
          <Typography paragraph>
            6. A statement that the information in the notification is accurate,
            and under penalty of perjury, that the complaining party is
            authorized to act.
          </Typography>
        </Box>

        <Typography variant="h6" gutterBottom>
          Designated Agent
        </Typography>
        <Typography paragraph>
          All DMCA notices should be sent to our designated agent as follows:
        </Typography>
        <Typography paragraph sx={{ paddingLeft: "20px", color: "gray" }}>
          Roque Thuo
          <br />
          Kama Thuo, PLLC
          <br />
          116 N. Roosevelt Rd, Suite 116,
          <br />
          Chandler, Arizona 85226
          <br />
          dmca@kamathuo.com
        </Typography>

        <Typography variant="h6" gutterBottom>
          Counter Notification
        </Typography>
        <Typography paragraph>
          If you believe that the material you posted was removed by mistake or
          misidentification, you can send a counter notification to our
          designated agent containing the following:
        </Typography>
        <Box pl={2}>
          <Typography paragraph>
            1. Your physical or electronic signature.
          </Typography>
          <Typography paragraph>
            2. Identification of the material that has been removed and the
            location where it appeared.
          </Typography>
          <Typography paragraph>
            3. A statement under penalty of perjury that you have a good faith
            belief that the material was removed by mistake.
          </Typography>
          <Typography paragraph>
            4. Your name, address, and a statement that you consent to the
            jurisdiction of the Federal District Court for your location.
          </Typography>
          <Typography sx={{ fontStyle: "italic", color: "gray" }}>
            This Notice was last revised on April 8, 2024.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default DmcaNotice;
