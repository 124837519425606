import React, { useState } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogActions,
  Container,
  Divider,
  Stack,
} from "@mui/material";
import ContactUsForm from "../common/ContactUsForm";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import ConstructionIcon from "@mui/icons-material/Construction";
import KamathuoContactUsCommonContent from "./KamathuoContactUsCommonContent";
import SetDocumentMeta from "../common/SetDocumentMeta";
import KamathuoAiComplianceWizard from "./KamathuoAiComplianceWizard";

const ENABLE_COMPLIANCE_CHECKER_MAINTENANCE_MODE = true;

const KamathuoAiComplianceChecker = ({ baseConfig }) => {
  SetDocumentMeta({
    title:
      "AI Compliance Law Services | Ensure AI Regulatory Compliance in the U.S.",
    description:
      "Stay ahead of AI compliance laws with our AI Compliance Checker for U.S. entities. Get expert guidance on AI ethics, and regulatory requirements \
        to ensure your AI systems meet legal standards. Contact Kama Thuo, PLLC for AI compliance consulting today.",
    keywords:
      "AI compliance law, AI regulatory compliance, artificial intelligence compliance, AI data privacy laws, AI ethics law, U.S. AI regulations, \
        AI legal risk assessment, AI law firm, AI compliance consulting, AI compliance checker",
  });
  const [contactDialogOpen, setContactDialogOpen] = useState(false);

  const openContactForm = () => {
    setContactDialogOpen(true);
  };

  const closeContactForm = () => {
    setContactDialogOpen(false);
  };

  return (
    <Container>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" gutterBottom>
          AI Compliance Checker for Entities in the United States
        </Typography>

        {ENABLE_COMPLIANCE_CHECKER_MAINTENANCE_MODE ? (
          // Render Maintenance Mode Information Block
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Card
                variant="outlined"
                sx={{
                  backgroundColor: "#f8f9fa",
                  borderLeft: "4px solid red",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CardContent sx={{ display: "flex", alignItems: "center" }}>
                  <HourglassEmptyIcon
                    sx={{ fontSize: 40, color: "red", mr: 2 }}
                  />
                  <ConstructionIcon
                    sx={{ fontSize: 40, color: "red", mr: 2 }}
                  />
                  <Box>
                    <Typography variant="h6" gutterBottom sx={{ color: "red" }}>
                      Automated Compliance Checker Wizard Offline for
                      Maintenance
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      We are making updates to our automated compliance checker
                      wizard and will be done soon. Contact us below for
                      immediate assistance.
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          // Render AI Compliance Wizard Component
          <KamathuoAiComplianceWizard />
        )}

        {/* Information Block 2 */}
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: "#f8f9fa",
              // borderLeft: "4px solid #28a745",
              borderLeft: "4px solid #007bff",
              padding: 2,
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Need Help Now? Contact Us to Discuss Your AI Compliance Needs.
              </Typography>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                alignItems="center"
              >
                <Box>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    gutterBottom
                  >
                    Call us for a free consultation
                  </Typography>
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    sx={{ fontWeight: "bold" }}
                  >
                    +1-602-837-0631
                  </Typography>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ ml: 3, mr: 3 }}
                />
                <Button
                  startIcon={<ConnectWithoutContactIcon />}
                  variant="contained"
                  color="primary"
                  onClick={openContactForm}
                  endIcon={<ArrowForwardIosIcon />}
                  sx={{ ml: 2 }}
                >
                  Contact Us
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {/* Contact Us Form Dialog */}
        <Dialog
          open={contactDialogOpen}
          onClose={closeContactForm}
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <KamathuoContactUsCommonContent>
              {(agreementText, organizationErrorText) => (
                <ContactUsForm
                  baseConfig={baseConfig}
                  formTitle="AI Compliance Help"
                  agreementText={agreementText}
                  isOrganizationRequired={true}
                  organizationErrorText={organizationErrorText}
                  onSuccessCallback={closeContactForm} // Close dialog on success
                />
              )}
            </KamathuoContactUsCommonContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeContactForm}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default KamathuoAiComplianceChecker;
