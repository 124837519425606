import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Typography, Button, Container } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { getImageUrl } from "../../utils/helpers";

const HeaderCarouselItem = ({ item, baseConfig }) => {
  // Get site branding colors
  const { priColor, buttColor, buttHoverColor } = baseConfig.siteBranding;

  // Check if the item is a video based on its file extension
  const isVideo = item.image.endsWith(".mp4");

  // Media query to determine screen size
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ backgroundColor: priColor }}>
      <Container disableGutters>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "center",
            justifyContent: isSmallScreen ? "center" : "space-between",
            height: isSmallScreen ? "auto" : "400px",
            paddingY: 2,
          }}
        >
          {/* Image/Video Section */}
          <Box
            sx={{
              width: isSmallScreen ? "100%" : "50%",
              height: isSmallScreen ? "auto" : "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
              marginBottom: isSmallScreen ? 2 : 0,
            }}
          >
            {isVideo ? (
              <video
                src={getImageUrl(item.image)}
                alt={item.name}
                style={{
                  height: isSmallScreen ? "auto" : "100%",
                  width: isSmallScreen ? "100%" : "auto",
                  maxWidth: "100%",
                }}
                autoPlay
                loop
                muted
              />
            ) : (
              <img
                src={getImageUrl(item.image)}
                alt={item.name}
                style={{
                  height: isSmallScreen ? "auto" : "100%",
                  width: isSmallScreen ? "100%" : "auto",
                  maxWidth: "100%",
                }}
              />
            )}
          </Box>

          {/* Text and Button Section */}
          <Box
            textAlign="center"
            color="white"
            sx={{
              width: isSmallScreen ? "100%" : "50%",
              height: isSmallScreen ? "auto" : "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Typography variant="h4" gutterBottom>
              {item.name}
            </Typography>
            <Typography variant="body1" sx={{ color: "white" }}>
              {item.description}
            </Typography>
            <Button
              href={item.link}
              variant="contained"
              size="medium"
              sx={{
                mt: 2,
                width: 150,
                backgroundColor: buttColor,
                "&:hover": {
                  backgroundColor: buttHoverColor,
                },
              }}
            >
              Read More
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HeaderCarouselItem;
