import React from "react";
import { useMediaQuery, Typography, Grid, Paper, Button } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import ReusableTitle from "./ReusableTitle";
import { getImageUrl } from "../../utils/helpers";
import { blue } from "@mui/material/colors";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: "auto",
  maxWidth: "100%", // Keep 100% for small screens to use the full width
}));

const StyledImageGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center", // Center image horizontally
  alignItems: "center", // Center image vertically
  [theme.breakpoints.down("sm")]: {
    alignItems: "center", // Center image on small screens as well
  },
  overflow: "hidden", // Prevent image overflow
}));

const StyledImg = styled("img")({
  width: "100%", // Make image responsive
  maxWidth: "350px",
  maxHeight: "300px",
  cursor: "pointer", // Make the image appear clickable
});

// Scroll to element function - to scroll to anchor within the same page
const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

// Utility function to determine link component and props
const getLinkComponentProps = (link, linkExternal) => {
  let LinkComponent;
  let linkProps;

  if (linkExternal) {
    LinkComponent = "a";
    linkProps = { href: link, target: "_blank", rel: "noopener noreferrer" };
  } else if (link?.startsWith("#")) {
    LinkComponent = "a"; // Use 'a' tag for anchor links
    linkProps = {
      href: link,
      onClick: (e) => {
        e.preventDefault();
        scrollToElement(link.substring(1));
      },
    };
  } else {
    LinkComponent = RouterLink;
    linkProps = { to: link };
  }

  return { LinkComponent, linkProps };
};

const BodyContentBlock = ({ content, baseConfig }) => {
  const {
    contentId,
    image,
    text,
    title,
    imagePosition,
    link,
    linkExternal,
    newsLink,
    newsLinkExternal,
  } = content;
  const { priColor, buttColor, buttHoverColor } = baseConfig.siteBranding;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const contentMaxWidth = theme.breakpoints.values.lg - 44; // Use the theme's large breakpoint as the max width (subtract 44 to align to header)

  // Get link components and props for the Learn More button
  const {
    LinkComponent: LearnMoreLinkComponent,
    linkProps: learnMoreLinkProps,
  } = link ? getLinkComponentProps(link, linkExternal) : {};

  // Get link components and props for the News & Insights button
  const { LinkComponent: NewsLinkComponent, linkProps: newsLinkProps } =
    newsLink ? getLinkComponentProps(newsLink, newsLinkExternal) : {};

  return (
    <StyledPaper
      elevation={isSmallScreen ? 0 : 1}
      sx={{
        maxWidth: isSmallScreen ? "100%" : `${contentMaxWidth}px`,
        marginTop: "20px",
      }}
    >
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="stretch" // Make items fill the container height
      >
        {/* Image section */}
        <StyledImageGrid
          item
          xs={12}
          sm={imagePosition === "left" ? 6 : 5}
          lg={imagePosition === "left" ? 5 : 4}
          order={isSmallScreen ? 1 : imagePosition === "left" ? 1 : 2} // Display image on the left if imagePosition is left, always left/top for small screens
          imageposition={imagePosition}
        >
          <StyledImg
            alt={title}
            src={getImageUrl(image)}
            onClick={() => link && (window.location = link)}
          />
        </StyledImageGrid>

        {/* Text section */}
        <Grid
          item
          xs={12}
          sm={imagePosition === "left" ? 6 : 7}
          lg={imagePosition === "left" ? 7 : 8}
          container
          direction="column"
          justifyContent="center" // Center the text vertically
          order={isSmallScreen ? 2 : imagePosition === "left" ? 2 : 1} // Display text on the right if imagePosition is left, always right/bottom for small screens
        >
          {title && (
            <ReusableTitle
              title={title}
              isMainHeading={false}
              siteBranding={baseConfig.siteBranding}
              contentId={contentId}
            />
          )}

          <Typography
            paragraph
            sx={{ mt: 2 }}
            dangerouslySetInnerHTML={{ __html: text }}
          />

          {/* Button row */}
          <Grid container spacing={2} direction="row" sx={{ mt: 2 }}>
            {/* Learn More Button (only if link exists) */}
            {link && (
              <Grid item>
                <Button
                  component={LearnMoreLinkComponent}
                  {...learnMoreLinkProps}
                  variant="contained"
                  size="medium"
                  sx={{
                    width: 150, // Width of learn more button
                    px: 1, // Add padding on the left and right of the text
                    backgroundColor: buttColor,
                    "&:hover": {
                      // backgroundColor: buttHoverColor,
                      backgroundColor: "#f0f0f0",
                      color: "#4ABDAC",
                    },
                  }}
                >
                  Learn More
                </Button>
              </Grid>
            )}

            {/* News & Insights Button (only if newsLink exists) */}
            {newsLink && (
              <Grid item>
                <Button
                  component={NewsLinkComponent}
                  {...newsLinkProps}
                  variant="contained"
                  size="medium"
                  sx={{
                    width: 180, // Width of News & Insights button
                    backgroundColor: "#96858F",
                    // color: "#ffffff",
                    "&:hover": { backgroundColor: "#f0f0f0", color: "#96858F" },
                  }}
                >
                  News & Insights
                </Button>
              </Grid>
            )}

            {/* Get in Touch Button */}
            <Grid item>
              <Button
                startIcon={<ConnectWithoutContactIcon />}
                variant="contained"
                size="medium"
                sx={{
                  width: 180, // Width of Get in Touch button
                  backgroundColor: "#9099A2",
                  "&:hover": {
                    // backgroundColor: "#707a85",
                    backgroundColor: "#f0f0f0",
                    color: "#9099A2",
                  },
                }}
                href="/contact"
              >
                Get in Touch
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default BodyContentBlock;
