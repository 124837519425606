export const kamathuoNavbarConfig = [
  {
    title: "Services",
    authRequired: false,
    children: [
      {
        title: "Patents",
        path: "/patents",
      },
      {
        title: "Artificial Intelligence",
        path: "/ai",
      },
      {
        title: "Wireless Technology",
        path: "/wireless",
      },
    ],
  },
  {
    title: "Professionals",
    path: "/professionals",
    authRequired: false,
    children: [],
  },
  {
    title: "News & Insights",
    path: "/news-insights",
    authRequired: false,
    children: [],
  },
  // {
  //   title: "News & Insights",
  //   path: "external",
  //   externalUrl: "https://news-insights.kamathuo.com/en-us/",
  //   authRequired: false,
  //   children: [],
  // },
  {
    title: "Resources",
    authRequired: false,
    children: [
      {
        title: "Patent AI Resources",
        path: "/patent-ai-resources",
      },
      {
        title: "AI Compliance Checker",
        path: "/ai-compliance-checker",
      },
    ],
  },
  {
    title: "Disclaimer",
    path: "/disclaimer",
    authRequired: false,
    children: [],
  },

  // ... more menu items
];
